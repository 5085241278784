import client from './../apiService';

class OutletService{
    getOutletLazilyBySearch(counter:number,searchString:string, regionFilter:Array<number>,positionId:number){
        const url=`Outlet/GetOutletBySearch?positionId=`+positionId;
        return client.post(url,{
            counter:counter,
            searchString:searchString,
            regionFilter:regionFilter,
            headers:{
                'Content-Type':'application/json'
            }
        });
    }
    getOutletCountByFilters(searchString: string, regionFilter: Array<number>, positionId: number){
        const url=`Outlet/GetActiveOutletCountByFilter?positionId=`+positionId;
        return client.post(url,{
            searchString: searchString,
            regionFilter: regionFilter,
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
    getOutletOfPosition(positionId:number){
        const url=`Outlet/GetOutletOfPosition?positionId=${positionId}`;
        return client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
    getOutlets(regionIds:Array<number>){
        const url=`Outlet/GetOutlet`;
        return client.post(url,regionIds,{
            headers:{
                'Content-Type':'application/json'
            }
        });
    }
}
export default new OutletService();