











































































































































































































































import {Component, Vue, Watch, Prop, Emit} from 'vue-property-decorator';
    
    import DropDown from "@/components/common/Inputs/DropDown.vue";
    import Navbar from '@/components/common/Navbar.vue';
    import ExcelUploadButton from '@/components/common/Buttons/ExcelUploadButton.vue';
    import SideDrawer from '@/components/common/SideDrawer.vue';
    import Illustration from '@/components/common/Illustration.vue';
    import Snackbar from '@/components/common/Snackbar.vue'
    import ListItem from '@/components/common/List/ListItem.vue'
    import ListItemContent from '@/components/common/List/ListItemContent.vue'
    import GeographyService from './../../services/GeographyService';
    import DistributorService from "@/services/DistributorService";
    import OutletService from "@/services/OutletService";
    import EmployeeService from './../../services/EmployeeService';
    import CompanyModuleService from "@/services/CompanyModuleService"
    import PositionCodeService from '@/services/PositionCodeService';
    import DetailsCard from "@/components/common/DetailsCard.vue";
    import SearchBar from "@/components/common/SearchBar.vue";
    import SearchableList from "@/components/common/SearchableList.vue";
    import ActionablePopup from '@/components/common/Popup/ActionablePopup.vue'
    import Popup from '@/components/common/Popup/Popup.vue'
    import DataTable from '@/components/common/Tables/DataTable.vue'
    import TextButton from '@/components/common/Buttons/TextButton.vue'
    import {IPositionLevel, IEntityMinWithParent, IEmployee, IReportingToPositions, IPositionCodeList, 
    IRegion, ITerritory, IPositionCodeView, PositionLevel, IBeat, PositionLevelNomenclature, IPosition, IDistributor,IOutlet} from '@/interfaces/positioncode';
    import {ILink, ITableHeader} from '@/interfaces/common'
    import { colors, VLayout } from 'vuetify/lib';
    import CompanySettingService from "@/services/CompanySettingServics";

    @Component({
        components: {
            SearchBar,
            Illustration,
            Navbar,
            ExcelUploadButton,
            SideDrawer,
            Snackbar,
            DetailsCard,
            DropDown,
            SearchableList,
            ActionablePopup,
            Popup,
            ListItem,
            ListItemContent,
            DataTable,
            TextButton
        }
    })
    export default class Create extends Vue{
        // for illustration
        private description: string = "No Positions Found" 
        private subdescription: string = "Please create a position to begin with"
        //for navbar
        private sidedrawerTitle: string = "Fill Position Details"
        //for side drawer
        private sideDrawer:boolean = false
        private valid:boolean = false
        private highestPositionLevel: string = ""
        private searchEmployee: string = ""
        private employeeItems: Array<IEmployee> = []
        private empLoading: boolean = false
        private reportingToItems: Array<IReportingToPositions> = []
        private reportingToPositionSearch: string = ''
        private positionLevels: Array<IPositionLevel> = []
        private allPositionLevels: Array<IPositionLevel> = []
        private timeout !: NodeJS.Timeout
        private message: string = ''
        private snackbar: boolean = false
        private snackbarColor: string = 'success'
        private showPositionList: boolean = true
        //flags for create or edit check
        private createPosition: boolean = true
        private menuItems: Array<ILink> = []
        // for menu Side Drawer
        private menuSideDrawerTitle: string = ""
        private menuSideDrawerSubTitle: string = ""
        private menuSideDrawer: boolean = false
        private regionsOfCompanyOriginal: Array<IEntityMinWithParent> = []
        private territoriesOfCompanyOriginal: Array<IEntityMinWithParent> = []
        private regionsOfCompany: Array<any> = []
        private territoriesOfCompany: Array<any> = []
        private selectedTerritories: Array<number> = []
        private selectedRegions: Array<number> = []
        private selectedTerritoriesToDisplay: Array<number> = []
        private selectedRegionsToDisplay: Array<number> = []
        private PositionForCard: IPositionCodeView = {
            PositionName: '',
            PositionCode: '',
            PositionLevel: '',
            AttachedTo: '',
            ReportingTo: '',
            ReportingToLevel: '',
        }
        private vacantBeats: Array<IBeat> = []
        private selectedBeats: Array<IBeat> = []
        private distributors: Array<IDistributor> = []
        private selectedDistributors: Array<IDistributor> = []
        private outlets:Array<IOutlet>=[]
        private selectedOutlet:Array<IOutlet>=[]
        private beatSearch: string = ""
        private distributorSearch: string = ""
        private outletSearch:string=""
        private counter: number = 0
        private selectedPositionId: number = 0
        private positionSearch: string = ""
        private positionCodes: Array<IPositionCodeList> = []
        private totalPositionCodes: number = 0
        private pages: number = 0
        private page: number = 1
        private inactivePositionCodes: Array<IPositionCodeList> = []
        private positionCodeLoading: boolean = false
        private selectedActionMenu: number = -1
        private editedItem: IPosition = {
            Name: '',
            Code: '',
            Level: {
                text: '',
                level: 0,
                value: ''
            },
            Employee: {
                erpId: '',
                id: 0,
                name: ''
            },
            VacantPosition: false,
            ReportingToPosition: {
                codeId: '',
                id: 0,
                level: 0,
                name: ''
            },
            Id: 0
        }
        private defaultItem: IPosition = {
            Name: '',
            Code: '',
            Level: {
                text: '',
                level: 0,
                value: ''
            },
            Employee: {
                erpId: '',
                id: 0,
                name: ''
            },
            VacantPosition: false,
            ReportingToPosition: {
                codeId: '',
                id: 0,
                level: 0,
                name: ''
            },
            Id: 0
        }
        private distCount : number = 0;
        private outletCount:number=0;
        public IsMT:boolean=false;
        private beatCount : number = 0;
        private warningPopup: boolean = false
        private warningPopupMsg: string = ""
        private confirmationPopup: boolean = false
        private confirmationPopupTitle: string = ""
        private confirmationPopupSubtitle: string = ""
        private confirmationPopupText: string = ""
        private cancelBtnText: string = ""
        private confirmBtnText: string = ""
        private tableHeaders: Array<ITableHeader> = [
            {
                text: 'Position Name',
                value: 'name',
                sortable: false,
                default: ''
            },
            {
                text: 'Pos. Code',
                value: 'codeId',
                sortable: false,
                default: ''
            },
            {
                text: 'Pos. Level',
                value: 'level',
                sortable: false,
                default: ''
            },
            {
                text: 'Attached To',
                value: 'attachedTo',
                sortable: false,
                default: 'VACANT'
            },
            {
                text: 'Last Attached To',
                value: 'lastAttachedTo',
                sortable: false,
                default: 'NA'
            },
            {
                text: 'Reporting To',
                value: 'reportingTo',
                sortable: false,
                default: 'NA'
            },
            {
                text: 'Rep. Pos. Level',
                value: 'reportingToPositionLevel',
                sortable: false,
                default: 'NA'
            },
            {
                text: 'Actions',
                value: 'action',
                sortable: false,
                default: ''
            }
        ]
        private inactivePositions: boolean = false
        private creatingOrUpdatingPosition: boolean = false;
        private activeVacantPositions: boolean = false;
        
        get checkPositionLevel(): boolean{
            return this.editedItem.Level.level ==  this.PositionLevelEnum[this.highestPositionLevel];
        }
        get formValidate(): Vue &{validate: () => boolean}{
            return this.$refs.form as Vue & {validate: () => boolean}
        }
        get formReset(): Vue &{reset: () => void}{
            return this.$refs.form as Vue & {reset: () => void}
        }
        get formResetValidation(): Vue &{resetValidation: () => void}{
            return this.$refs.form as Vue & {resetValidation:() => void}
        }
        get employeeValidation(): Array<any>{
            //FIXED : employee was coming null on repeated backspace in employee search
            return ((this.editedItem.Employee != null && this.editedItem.Employee.id != 0) || this.editedItem.VacantPosition) ? [true] :  [`Required`];
        }
        get reportingToValidation(): Array<any>{
            return (this.editedItem.ReportingToPosition.id != 0 || this.checkPositionLevel) ? [true] : ['Required']
        }
        get PositionLevelEnum(): any{
            return PositionLevel;
        }
        get PositionLevelNomenclatureEnum(): any{
            return PositionLevelNomenclature;
        }
        get reportingToPositionSearchCount(): number{
            if(this.reportingToPositionSearch == null)
                return this.reportingToItems.length;
            let lowerCaseSearchString = this.reportingToPositionSearch.toLowerCase();
            return this.reportingToItems.filter(i => i.name.toLowerCase().includes(lowerCaseSearchString)).length;
        }
        @Watch('searchEmployee')
        searchEmployeeString(newEmp: string){
            clearTimeout(this.timeout);
            if(newEmp && newEmp.length > 2){
                this.timeout = setTimeout(() => {
                    this.getEmployees(newEmp);
                }, 1000);
            }
        }
        @Watch('beatSearch')
        searchBeat(searchString: string){
            this.counter = 0;
            clearTimeout(this.timeout);
            if(searchString && searchString.length > 2 || searchString.length == 0){
                this.timeout = setTimeout(() => {
                    this.GetBeats(this.counter, searchString, this.selectedRegions, this.selectedTerritories);
                }, 1000);
            }
        }
        @Watch('distributorSearch')
        searchDistributor(searchString: string){
            this.counter = 0;
            clearTimeout(this.timeout);
            if(searchString && searchString.length > 2 || searchString.length == 0){
                this.timeout = setTimeout(() => {
                    this.GetDistributors(this.counter, searchString, this.selectedRegions);
                }, 1000);
            }
        }
        @Watch('outletSearch')
        searchOutlet(searchString:string){
            this.counter=0;
            clearTimeout(this.timeout)
            if(searchString&&searchString.length>2||searchString.length==0){
                this.timeout=setTimeout(()=>{
                    this.GetOutlets(this.counter,searchString,this.selectedRegions);
                })
            }
        }
        @Watch('positionSearch')
        searchPositionCode(newVal: string){
            if(newVal && newVal.length > 2){
                this.timeout = setTimeout(() => {
                    this.inactivePositions ? this.getInactivePositionCodes(newVal, 1) :  this.getPositionCodes(newVal, 1);
                }, 1000);
            }else if(newVal.length == 0){
                this.timeout = setTimeout(() => {
                    this.inactivePositions ? this.getInactivePositionCodes("", 1) : this.getPositionCodes("", 1)
                }, 1000);
            }
        }

        public changeRegionFilter(regions: Array<IRegion>) {
            this.selectedRegionsToDisplay = regions.map(r => r.id);
            this.selectedRegions = regions.map(r => r.id);
            this.selectedTerritories = this.selectedTerritoriesToDisplay.length > 0 ? this.selectedTerritoriesToDisplay : [];
            this.counter = 0;
            switch(this.menuItems[this.selectedActionMenu].Text){
                case "Attach Beats":
                    this.GetBeats(this.counter, this.beatSearch, this.selectedRegions, this.selectedTerritories);
                    break;
                case "Attach Distributors":
                    this.GetDistributors(this.counter, this.distributorSearch,this.selectedRegions);
                    break;
               // case "Attach Outlets":
                //    this.GetOutlets(this.counter, this.outletSearch,this.selectedRegions);
                 //   break; 
                
                case "Attach Outlets":
                    this.GetOutlets(this.counter, this.distributorSearch,this.selectedRegions);
                    break;
                default:
                    console.log("Wrong SelectedMenuAction");
            }
        }
        public changeTerritoryFilter(territories: Array<ITerritory>){
            this.selectedTerritoriesToDisplay = territories.map(r => r.id);
            if(territories.length > 0)
                this.selectedTerritories = territories.map(r => r.id);
            else
                this.selectedTerritories = this.territoriesOfCompany.map(t =>t.id);
            this.counter = 0;
            this.GetBeats(this.counter, this.beatSearch, this.selectedRegions, this.selectedTerritories);
        }
        public loadMoreBeats(){
            this.counter += 1;
            let beatSearch = this.beatSearch;
            if(beatSearch.length < 3){
                beatSearch = "";
            }
            GeographyService.getBeatsLazilyBySearch(this.counter,beatSearch, this.selectedRegions, this.selectedTerritories).then( res => {
                let fromAPi : Array<IBeat> = res.data
                this.vacantBeats = this.vacantBeats.concat(fromAPi.filter(b => !this.selectedBeats.find(sb => sb.id === b.id)));
                this.vacantBeats.sort((a,b) => a.name.localeCompare(b.name))
            }).catch(err => {
                this.showCommunicationError(err);
                console.log(err);
            });
        }
        public selectBeat(beatIndex: number){
            this.selectedBeats.push(this.vacantBeats[beatIndex])
            this.vacantBeats.splice(beatIndex, 1);
            this.beatCount--;
            if(this.vacantBeats.length === 0){
                this.loadMoreBeats()
            }
        }
        public deselectBeat(beatIndex: number){
            let beat = this.selectedBeats[beatIndex];
            let i: number;
            let beatNameLC = beat.name.toLowerCase();
            for (i = 0; i < this.vacantBeats.length; i++) {
                if (this.vacantBeats[i].name.toLowerCase() > beatNameLC){
                    this.vacantBeats.splice(i,0, beat);
                    break;
                }
            }
            if(i == this.vacantBeats.length)
                this.vacantBeats.splice(i,0, beat);
            this.selectedBeats.splice(beatIndex, 1);
            this.beatCount++;
        }
        public menuDrawerConfirm(){
            switch(this.menuItems[this.selectedActionMenu].Text){
                case "Attach Beats":
                    PositionCodeService.attachBeats(this.selectedPositionId, this.selectedBeats.map(b => b.id)).then(res => {
                        this.snackbarColor = "success";
                        this.snackbar = true;
                        this.message = `Changes Saved Successfully`;
                        this.showPositionList = true;
                        this.closeMenuSideDrawer(false);
                        this.refreshPositionList();
                    }).catch(e => {
                        this.showCommunicationError(e);
                        console.log(e);
                    });
                    break;
                case "Attach Distributors":
                    PositionCodeService.attachDistributor(this.selectedPositionId, this.selectedDistributors.map(b => b.id)).then(res => {
                        this.snackbarColor = "success";
                        this.snackbar = true;
                        this.message = `Changes Saved Successfully`;
                        this.showPositionList = true;
                        this.closeMenuSideDrawer(false);
                        this.refreshPositionList();
                    }).catch(e => {
                        this.showCommunicationError(e);
                    })
                    break;

                // case "Attach Outlets":
                  //  PositionCodeService.attachOutlet(this.selectedPositionId, this.selectedOutlets.map(b => b.id)).then(res => {
                    //    this.snackbarColor = "success";
                    //    this.snackbar = true;
                      //  this.message = `Changes Saved Successfully`;
                       // this.showPositionList = true;
                        //this.closeMenuSideDrawer(false);
                      //  this.refreshPositionList();
                   // }).catch(e => {
                     //   this.showCommunicationError(e);
                  //  })
                    //break;
                case "Attach Outlets":
                        if(this.selectedOutlet.length>1)
                        {
                            this.snackbarColor = "red";
                            this.snackbar = true;
                            this.message = `Can't attach more than one outlet to a position code `;   
                        }
                        else{
                                PositionCodeService.attachOutlet(this.selectedPositionId, this.selectedOutlet.map(b => b.id)).then(res => {
                                this.snackbarColor = "success";
                                this.snackbar = true;
                                this.message = `Changes Saved Successfully`;
                                this.showPositionList = true;
                                this.closeMenuSideDrawer(false);
                                this.refreshPositionList();
                            }).catch(e => {
                                this.showCommunicationError(e);
                            })
                        }
                    break;
                default:
                    console.log("Wrong SelectedMenuAction");
            }
        }
        public showCommunicationError(err : any) {    
            this.snackbarColor = "red";
            this.snackbar = true;
            this.message = window.navigator.onLine ? `Some Error occured` : `Cannot reach server at the moment`;
        }
        public checkBoxIcon(item: any, list: Array<any>){
            if(list.find(i => i.id == item.id)){
                return "mdi-checkbox-marked";
            }else{
                return "mdi-checkbox-blank";
            }
        }
        public checkBoxStyle(item: any, list: Array<any>){
            if(list.find(i => i.id == item.id)){
                return "color: var(----primaryFABlue);";
            }else{
                return "color: var(--highlightGray);";
            }
        }
        public onScrollBeats(event: any){
            if(this.vacantBeats.length > 0){
                if(event.target.scrollTop + event.target.offsetHeight === event.target.scrollHeight){
                    this.loadMoreBeats();
                }
            }
        }

        public getEmployees(empStr: string): void{
            this.empLoading = true;
            EmployeeService.getEmployeeBySearch(empStr).then((response) => {
                this.employeeItems = response.data;
                this.empLoading = false;
            }).catch((error) => {
                this.showCommunicationError(error);
                console.log(error);
                this.empLoading = false;
            })
        }
        public getReportingToPositions(): void{
            PositionCodeService.getReportingToPositions(this.editedItem.Level.value).then((response) => {
                this.reportingToItems = response.data;
            }).catch((error) => {
                this.showCommunicationError(error);
                console.log(error);
            })
        }
        //Date: 31/08/2021
        //Asana: https://app.asana.com/0/139097763031412/1200843086789823/f
        //Reason: To make a single Save/Update Call even if user clicks Create/Update Multiple Times
        private createOrUpdatePosition():void{
            if(!this.creatingOrUpdatingPosition)
                this.createPosition ? this.savePositionCode() : this.updatePositionCode();
        }
        public savePositionCode(): void{
            let position = Object.assign({}, this.editedItem);
            console.log(position);
            this.creatingOrUpdatingPosition = true;
            PositionCodeService.savePositionCode({
                CodeId: position.Code,
                Level: position.Level.value,
                Name: position.Name,
                IsReportingToAttached: this.checkPositionLevel ? false : true,
                ReportingTo: position.ReportingToPosition.id,
                EmployeeId: position.Employee.id,
                IsPositionVacant: position.VacantPosition,
            }).then((response) => {
                console.log(response);
                this.snackbarColor = "success";
                this.snackbar = true;
                this.message = "Position Created Successfully";
                this.showPositionList = true;
                this.creatingOrUpdatingPosition = false;
                this.closeSideDrawer(false);
                this.clearForm();
                this.refreshPositionList();
            }).catch((error) => {
                this.creatingOrUpdatingPosition = false;
                if(error.response.status == 400){
                    this.snackbarColor = "red";
                    this.snackbar = true;
                    this.message = error.response.data;
                }
            })
        }
        public async GetBeats(counter: number, searchString: string, regionFilter: Array<number>, territoryFilter: Array<number> ){
            if(searchString.length < 3){
                searchString = "";
            }
            let countRes = GeographyService.getBeatsCountByFilter(searchString, regionFilter);
            GeographyService.getBeatsLazilyBySearch(counter,searchString, regionFilter, territoryFilter).then( res => {
                countRes.then(nRes => {
                    this.vacantBeats = res.data;
                    this.beatCount = nRes.data - this.vacantBeats.filter(b => this.selectedBeats.find(sb => sb.id === b.id)).length;
                    this.vacantBeats = this.selectedBeats.length > 0 ? this.vacantBeats.filter(b => !this.selectedBeats.find(sb => sb.id === b.id)) : this.vacantBeats;
                    this.vacantBeats.sort((a,b) => a.name.localeCompare(b.name));
                });
            }).catch(err => {
                this.showCommunicationError(err);
                console.log(err);
            })
        }
        public async GetSelectedBeats(positionId: number){
            GeographyService.getBeatsOfPosition(positionId).then(res => {
                this.selectedBeats =  res.data;
            }).catch(e => {
                this.showCommunicationError(e);
                console.log(e);
            })
        }
        public menuAction(position: IPositionCodeList, commandIndex: number){
            this.selectedPositionId = position.id;
            let level = this.positionLevels.find( p => p.level == position.level);
            let reportingToLevel = this.positionLevels.find( p => p.level == position.reportingToPositionLevel);
            this.PositionForCard =  {
                PositionName: position.name,
                PositionCode: position.codeId,
                PositionLevel: level !== undefined ?  level.text : "",
                AttachedTo: position.attachedTo,
                ReportingTo: position.reportingTo,
                ReportingToLevel: reportingToLevel !== undefined ?  reportingToLevel.text : "",
            };
            this.selectedActionMenu = commandIndex;
            switch (this.menuItems[commandIndex].Text) {
                //Attach Beats
                case "Attach Beats":
                    this.menuSideDrawerTitle = `Attach or Detach Beats`
                    this.menuSideDrawer = true;
                    this.menuSideDrawerSubTitle = position.name;
                    this.GetSelectedBeats(this.selectedPositionId);
                    this.GetBeats(this.counter, this.beatSearch, this.selectedRegions, this.selectedTerritories);
                    break;
                case "Attach Distributors":
                    this.menuSideDrawerTitle = `Attach or Detach Distributors`
                    this.menuSideDrawer = true;
                    this.menuSideDrawerSubTitle = position.name;
                    this.GetSelectedDistributors(this.selectedPositionId);
                    this.GetDistributors(this.counter, this.beatSearch, this.selectedRegions);
                    break;
             //   case "Attach Outlets":
               //     this.menuSideDrawerTitle = `Attach or Detach Outlets`
                 //   this.menuSideDrawer = true;
                   // this.menuSideDrawerSubTitle = position.name;
                //    this.GetSelectedOutlets(this.selectedPositionId);
                  //  this.GetOutlets(this.counter, this.beatSearch, this.selectedRegions);
                    //break;
                case "Deactivate Position": 
                    if((position.beatIds != null && position.beatIds.length>0) || (position.distributorIds != null && position.distributorIds.length>0) || position.attachedToId>0){
                        this.warningPopup = true;
                        this.warningPopupMsg = "User - "+(position.attachedTo != "" ? position.attachedTo+"<br>": "0<br>")+(position.distributorIds != null ? position.distributorIds.length : "0")+" distributors attached.<br>"
                        +(position.beatIds != null ? position.beatIds.length : "0")+" beats attached.";
                    }
                    else{
                        this.confirmationPopupTitle = "Confirm Deactivation";
                        this.confirmationPopupSubtitle = "Are you sure you wish to deactivate the Position?";
                        this.confirmationPopupText = "";
                        this.cancelBtnText = "Cancel";
                        this.confirmBtnText = "Confirm";
                        this.confirmationPopup = true;
                    }
                    break;
                case "Attach Outlets":
                    this.menuSideDrawerTitle = `Attach or Detach Outlets`
                    this.menuSideDrawer = true;
                    this.menuSideDrawerSubTitle = position.name;
                    this.GetSelectedOutlets(this.selectedPositionId);
                    this.GetOutlets(this.counter, this.beatSearch, this.selectedRegions);
                    break;                    
                default: console.log("Unhandled menuAction")
            }
        }
        public activatePosition(position: IPositionCodeList){
            this.selectedPositionId = position.id;
            this.confirmationPopupTitle = "Confirm Activation";
            this.confirmationPopupSubtitle = position.name+" position will be activated.";
            this.confirmationPopupText = "";
            this.cancelBtnText = "Keep Inactive";
            this.confirmBtnText = "Activate";
            this.confirmationPopup = true;
        }
        public editPosition(value: IPositionCodeList): void{
            this.createPosition = false;
            this.openDrawer('');
            
            this.searchEmployee = value.attachedTo;
            this.employeeItems = [];
            if(value.attachedToId != 0){
                this.employeeItems.push({
                    erpId: value.attachedErpId,
                    id: value.attachedToId,
                    name: value.attachedTo
                });
            }
            this.editedItem = Object.assign({}, {
                Name: value.name,
                Code: value.codeId,
                Level:  Object.assign({}, this.positionLevels.find(p => p.level == value.level)!),
                Employee: {
                    erpId: value.attachedErpId,
                    id: value.attachedToId,
                    name: value.attachedTo
                },
                VacantPosition: value.attachedToId != 0 ? false: true,
                ReportingToPosition: {
                    codeId: value.reportingToCodeId,
                    id: value.reportingToId,
                    level: value.reportingToPositionLevel,
                    name: value.reportingTo
                },
                Id: value.id
            })

            this.getReportingToPositions();
            console.log(this.editedItem);
        }
        public updatePositionCode(): void{
            let position = Object.assign({}, this.editedItem);
            console.log(position);
            this.creatingOrUpdatingPosition = true;
            PositionCodeService.updatePositionCode({
                id: position.Id,
                CodeId: position.Code,
                Level: position.Level.value,
                Name: position.Name,
                IsReportingToAttached: this.checkPositionLevel ? false : true,
                ReportingTo: position.ReportingToPosition.id,
                EmployeeId: position.Employee.id,
                IsPositionVacant: position.VacantPosition,
            }).then((response) => {
                console.log(response);
                this.snackbarColor = "success";
                this.snackbar = true;
                this.message = "Changes Saved Successfully";
                this.showPositionList = true;
                this.creatingOrUpdatingPosition = false;
                this.closeSideDrawer(false);
                this.clearForm();
                this.refreshPositionList();
            }).catch((error) => {
                this.creatingOrUpdatingPosition = false;
                if(error.response.status == 400){
                    this.snackbarColor = "red";
                    this.snackbar = true;
                    this.message = error.response.data;
                }
            })
        }
        public clearForm(): void{
            this.editedItem = Object.assign({}, this.defaultItem);           
            this.searchEmployee = '';
            this.employeeItems = [];
            this.reportingToItems = [];
        }
        public async refreshPositionList(){
            this.inactivePositions ? await this.getInactivePositionCodes(this.positionSearch, 1) : await this.getPositionCodes(this.positionSearch, 1);
            
        }
        public openDrawer(value: string): void{
            this.sidedrawerTitle = this.createPosition ? 'Fill Position Details': 'Edit Position';
            this.clearForm();
            this.sideDrawer = true;
            this.formResetValidation.resetValidation();
        }
        public closeSideDrawer(value: boolean): void{
            this.hideWindowScrollBar(value);
            this.sideDrawer = value;
        }
        private getRefAsAny(refKey: string){
            return (this.$refs[refKey] as any)
        }
        public closeMenuSideDrawer(value: boolean): void{
            this.hideWindowScrollBar(value);
            this.menuSideDrawer = value;
            this.selectedBeats = [];
            this.beatSearch = "";
            this.counter = 0;
            this.vacantBeats = [];
            this.selectedDistributors = [];
            this.distributorSearch = "";
            this.distributors = [];
            this.getRefAsAny("regionsDropDown").clickItem(this.regionsOfCompany);
        }
        public closeSnackbar(value: boolean): void{
            this.snackbar = value;
        }
        public async getHighestPositionLevel(): Promise<boolean>{
            return new Promise<boolean>(res => {
                PositionCodeService.getHighestPositionLevel().then((response) => {
                    console.log(response.data);
                    this.highestPositionLevel = response.data;
                    res(true);
                }).catch((error) => {
                    this.showCommunicationError(error);
                    console.log(error);
                })
            });
        }
        public async getPositionLevels(): Promise<boolean>{
            return new Promise<boolean>(res => {
                CompanySettingService.getCompanyNomenclatures().then((response) => {
                    let data = response.data;
                    //TODO logic can be improved
                    let keys = [ "L1Position", "L2Position", "L3Position", "L4Position", "L5Position", "L6Position", "L7Position", "L8Position", "Distributor" ];                   
                    debugger;
                    for(const key in keys){
                        this.allPositionLevels.push(
                            {
                                text: data[keys[key]].displayName,
                                value: keys[key],
                                level: this.PositionLevelNomenclatureEnum[keys[key]]
                            }
                            
                        );
                        this.positionLevels = this.allPositionLevels.filter(p => p.level >= this.PositionLevelEnum[this.highestPositionLevel]);
                    }
                    res(true);
                }).catch((error) => {
                    this.showCommunicationError(error);
                    console.log(error);
                });
            });
        }
        public async getPositionCodes(searchTerm: string, value: number): Promise<boolean>{
            this.positionCodeLoading = true;
            if (this.activeVacantPositions)
            {
                    return new Promise<boolean>(res => {
                PositionCodeService.GetVacantPositionCodes(this.positionSearch, value).then((response) => {
                    this.positionCodes = response.data.positionCodes;
                    this.pages = response.data.pages;
                    this.totalPositionCodes = response.data.total;
                    this.positionCodeLoading = false;
                    if(this.positionCodes.length > 0){
                        this.showPositionList = true;
                    }else{
                        this.showPositionList = false;
                        this.description = this.positionSearch.length == 0 ? "No Positions Found" : "No matching result found" ;
                        this.subdescription = this.positionSearch.length == 0 ? "Please create a position to begin with" : "Make sure you typed correctly or try finding in inactive positions";
                    }
                    res(true);
                }).catch((error) => {
                    this.showCommunicationError(error);
                    console.log(error);
                    this.positionCodeLoading = false;
                })
            })
            }
            else
            {
                return new Promise<boolean>(res => {
                PositionCodeService.getPositionCodes(this.positionSearch, value).then((response) => {
                    this.positionCodes = response.data.positionCodes;
                    this.pages = response.data.pages;
                    this.totalPositionCodes = response.data.total;
                    this.positionCodeLoading = false;
                    if(this.positionCodes.length > 0){
                        this.showPositionList = true;
                    }else{
                        this.showPositionList = false;
                        this.description = this.positionSearch.length == 0 ? "No Positions Found" : "No matching result found" ;
                        this.subdescription = this.positionSearch.length == 0 ? "Please create a position to begin with" : "Make sure you typed correctly or try finding in inactive positions";
                    }
                    res(true);
                }).catch((error) => {
                    this.showCommunicationError(error);
                    console.log(error);
                    this.positionCodeLoading = false;
                })
            })
            }
            
        }
        public changePage(value: number): void{
            this.page = value;
            this.inactivePositions ? this.getInactivePositionCodes(this.positionSearch, value) : this.getPositionCodes(this.positionSearch, value);
        }
        private async GetOutlets(counter: number, distSearch: string, selectedRegions: Array<number>){
            if(distSearch.length<3){
                distSearch="";
            }
            let countRes=OutletService.getOutletCountByFilters(distSearch, selectedRegions, this.selectedPositionId);
            OutletService.getOutletLazilyBySearch(counter, distSearch, selectedRegions, this.selectedPositionId).then(res=>{
                countRes.then(countRes=>{
                    this.outlets=res.data;
                    this.outletCount=countRes.data-this.selectedOutlet.length;
                    this.outlets=this.selectedOutlet.length > 0 ? this.outlets.filter(b => !this.selectedOutlet.find(sb => sb.id === b.id)) : this.outlets;
                    this.outlets.sort((a,b)=>a.name.localeCompare(b.name));
                })
            })
        }
        private async GetSelectedOutlets(selectedPositionId: number){
            OutletService.getOutletOfPosition(selectedPositionId).then(res=>{
                this.selectedOutlet=res.data;
            }).catch(e=>{
                console.log(e);
            })

        }
        public selectOutlet(index:number){
            this.selectedOutlet.push(this.outlets[index])
            this.outlets.splice(index,1);
            this.outletCount--;
            if(this.outlets.length==0){
                this.loadMoreOutlets()
            }
        }
        public deselectOutlets(index:number){
            let outlets=this.selectedOutlet[index];
            let i:number;
            let outletNameLC=outlets.name.toLowerCase();
            for(i=0;i<this.outlets.length;i++){
                if(this.outlets[i].name.toLowerCase()>outletNameLC){
                    this.outlets.splice(i,0,outlets);
                    break;
                }
            }
            if(i==this.outlets.length)
                this.outlets.splice(i,0,outlets);
            this.selectedOutlet.splice(index,1);
            this.outletCount++;
        }
        public onScrollOutlets(event:any){
            if(this.outlets.length>0){
                if(event.target.scrollTop+event.target.offsetHeight==event.target.scrollHeight){
                    this.loadMoreOutlets();
                }
            }
        }
        public loadMoreOutlets(){
            this.counter+=1;
            let SearchString=this.outletSearch;
            if(SearchString.length<3){
                SearchString="";
            }
            OutletService.getOutletLazilyBySearch(this.counter,SearchString, this.selectedRegions, this.selectedPositionId).then(res=>{
                let fromApi:Array<IOutlet>=res.data;
                this.outlets=this.outlets.concat(fromApi.filter(b=>!this.selectedOutlet.find(sb=>sb.id===b.id)));
                this.outlets.sort((a,b)=>a.name.localeCompare(b.name))
            }).catch(err=>{
                this.showCommunicationError(err);
                console.log(err);
            })
        }

        private async GetDistributors(counter: number, distSearch: string, selectedRegions: Array<number>) {
            if(distSearch.length < 3){
                distSearch = "";
            }
            let countRes = DistributorService.getDistributorCountByFilters(distSearch,selectedRegions,this.selectedPositionId);
            DistributorService.getDistributorLazilyBySearch(counter, distSearch, selectedRegions, this.selectedPositionId).then( res =>{
                countRes.then(countRes => {
                    this.distributors = res.data;
                    this.distCount = countRes.data - this.distributors.filter(b => this.selectedDistributors.find(sb => sb.id === b.id)).length;
                    this.distributors = this.selectedDistributors.length > 0 ? this.distributors.filter(b => !this.selectedDistributors.find(sb => sb.id === b.id)) : this.distributors;
                    this.distributors.sort((a,b) => a.name.localeCompare(b.name));
                })
            }).catch( e => {
                this.showCommunicationError(e);
                console.log(e);
            })
        }
        private async GetSelectedDistributors(selectedPositionId: number) {
            DistributorService.getDistributorOfPosition(selectedPositionId).then( res =>{
                this.selectedDistributors = res.data;
            }).catch( e => {
                console.log(e);
            })
        }
        public selectDistributor(index: number){
            this.selectedDistributors.push(this.distributors[index])
            this.distributors.splice(index, 1);
            this.distCount--;
            if(this.distributors.length === 0){
                this.loadMoreDistributors()
            }
        }
        public deselectDistributor(index: number){
            let distributor = this.selectedDistributors[index];
            let i: number;
            let distributorNameLC = distributor.name.toLowerCase();
            for (i = 0; i < this.distributors.length; i++) {
                if (this.distributors[i].name.toLowerCase() > distributorNameLC){
                    this.distributors.splice(i,0, distributor);
                    break;
                }
            }
            if(i == this.distributors.length)
                this.distributors.splice(i,0, distributor);
            this.selectedDistributors.splice(index, 1);
            this.distCount++;
        }
        public onScrollDistributors(event: any){
            if(this.distributors.length > 0){
                if(event.target.scrollTop + event.target.offsetHeight === event.target.scrollHeight){
                    this.loadMoreDistributors();
                }
            }
        }
        public loadMoreDistributors(){
            this.counter += 1;
            let SearchString = this.distributorSearch;
            if(SearchString.length < 3){
                SearchString = "";
            }
            DistributorService.getDistributorLazilyBySearch(this.counter,SearchString, this.selectedRegions, this.selectedPositionId).then( res => {
                let fromAPi : Array<IDistributor> = res.data;
                this.distributors = this.distributors.concat(fromAPi.filter(b => !this.selectedDistributors.find(sb => sb.id === b.id)));
                this.distributors.sort((a,b) => a.name.localeCompare(b.name))
            }).catch(err => {
                this.showCommunicationError(err);
                console.log(err);
            });
        }

        public confirmAction(value: any):void{
            if(this.inactivePositions){
                PositionCodeService.activatePosition(value).then((res) => {
                    this.snackbarColor = "success";
                    this.message = "Changes Saved Successfully";
                    this.actionablePopup();
                    this.switchPosition();
                }).catch((err) => {
                    this.showCommunicationError(err);
                    console.log(err);
                })
            }
            else{
                PositionCodeService.deactivatePosition(value).then((res) => {
                    this.snackbarColor = "red";
                    this.message = "Position deactivated and moved to inactive list";
                    this.actionablePopup();
                    this.refreshPositionList();
                }).catch((err) => {
                    this.showCommunicationError(err);
                    console.log(err);
                })
            }
        } 
        public actionablePopup(){
            this.snackbar = true;
            this.confirmationPopup = false;
            this.showPositionList = true;
        } 
        private async GetMenuActions(): Promise<void> {
            CompanySettingService.getDistributorMapping().then(res => {
                this.menuItems = [
                    {
                        Text: "Attach Beats",
                        Color: "primaryFABlue"
                    },
                    {
                        Text: "Deactivate Position",
                        Color: "red"
                    },
                    ];
                if(this.IsMT==true)
                {
                    this.menuItems.push({
                        Text:"Attach Outlets",
                        Color:"primaryFABlue"
                    })
                }
                if(res.data == "UserDistributor"){
                    this.menuItems.push({
                        Text: "Attach Distributors",
                        Color: "primaryFABlue"
                    });
                }

            })
            
        }
        public async IsModernTrade(){
            CompanyModuleService.IsModernTrade().then((res:any)=>{
                this.IsMT=res.data;
            })
            .catch((err:any)=>{
                console.log(err);
            })
            
        }

        public async getRegions(): Promise<void>{
            GeographyService.getAllGeographiesOfType('region').then( res => {
                this.regionsOfCompanyOriginal = res.data;
                this.regionsOfCompany = this.regionsOfCompanyOriginal; //Extra Layer can be used if regions/territories are to be filtered w.r.t selected parent
                this.selectedRegions = this.regionsOfCompanyOriginal.map(r => r.id);
            }).catch( err => {
                this.showCommunicationError(err);
                console.log(err);
            })
        }
        public async getInactivePositionCodes(searchTerm: string, page: number): Promise<boolean>{
            this.positionCodeLoading = true;
            return new Promise<boolean>(res => {
                PositionCodeService.getInactivePositionCodes(searchTerm, page).then((response) => {
                    this.positionCodes = response.data.positionCodes;
                    this.pages = response.data.pages;
                    this.totalPositionCodes = response.data.total;
                    this.positionCodeLoading = false;
                    res(true);
                }).catch((error) => {
                    this.showCommunicationError(error);
                    console.log(error);
                    this.positionCodeLoading = false;
                })
            })
        }
        public switchPosition(){
            if(this.inactivePositions){
                this.tableHeaders = [
                    {
                        text: 'Position Name',
                        value: 'name',
                        sortable: false,
                        default: ''
                    },
                    {
                        text: 'Pos. Code',
                        value: 'codeId',
                        sortable: false,
                        default: ''
                    },
                    {
                        text: 'Pos. Level',
                        value: 'level',
                        sortable: false,
                        default: ''
                    },
                    {
                        text: 'Actions',
                        value: 'action',
                        sortable: false,
                        default: ''
                    }
                ];
                this.getInactivePositionCodes(this.positionSearch, 1);
                this.activeVacantPositions = false;
            }
            else{
                this.tableHeaders = [
                    {
                        text: 'Position Name',
                        value: 'name',
                        sortable: false,
                        default: ''
                    },
                    {
                        text: 'Pos. Code',
                        value: 'codeId',
                        sortable: false,
                        default: ''
                    },
                    {
                        text: 'Pos. Level',
                        value: 'level',
                        sortable: false,
                        default: ''
                    },
                    {
                        text: 'Attached To',
                        value: 'attachedTo',
                        sortable: false,
                        default: 'VACANT'
                    },
                    {
                        text: 'Last AttachedTo',
                        value: 'lastAttachedTo',
                        sortable: false,
                        default: 'NA'
                    },
                    {
                        text: 'Reporting To',
                        value: 'reportingTo',
                        sortable: false,
                        default: 'NA'
                    },
                    {
                        text: 'Rep. Pos. Level',
                        value: 'reportingToPositionLevel',
                        sortable: false,
                        default: 'NA'
                    },
                    {
                        text: 'Actions',
                        value: 'action',
                        sortable: false,
                        default: ''
                    }
                ];
                this.getPositionCodes(this.positionSearch, 1);
            }
        }
        async created(){
            this.positionCodeLoading = true;
            this.getHighestPositionLevel().then(res => {
                this.getPositionLevels().then(res => {
                    this.getPositionCodes(this.positionSearch, 1);
                })
            });
            this.GetMenuActions().then(_ => _);
            this.getRegions().then(_ => _);
        }

        private hideWindowScrollBar(hideIt: boolean) {
            window.document.documentElement.style.overflowY = hideIt ? 'hidden' : 'scroll';
        }
        mounted()
        {
            this.IsModernTrade();
        }
    }
