import client from './../apiService';

class DistributorService{
    getDistributorLazilyBySearch(counter: number, searchString: string, regionFilter: Array<number>, positionId: number){
        const url = `Distributor/GetDistributorBySearch?positionId=`+positionId;
        return client.post(url, {
            counter: counter,
            searchString: searchString,
            regionFilter: regionFilter,
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
    getDistributorCountByFilters(searchString: string, regionFilter: Array<number>, positionId: number){
        const url = `Distributor/GetActiveDistributorCountByFilters?positionId=`+positionId;
        return client.post(url, {
            searchString: searchString,
            regionFilter: regionFilter,
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
    getDistributorOfPosition(positionId: number){
        const url = `Distributor/GetDistributorsOfPosition?positionId=${positionId}`;
        return client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
    getDistributors(regionIds: Array<number>){
        const url = `Distributor/GetDistributors`;
        return client.post(url, regionIds, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}

export default new DistributorService();