





















import {Component, Emit, Prop, Vue} from 'vue-property-decorator'
import TextButton from '@/components/common/Buttons/TextButton.vue'
@Component({
    components: {
        TextButton
    }
})
export default class Popup extends Vue {
    @Prop({default: false, required: true}) readonly popupDialog!: boolean
    @Prop() readonly title !: string
    @Prop() readonly subtitle !: string
    @Prop() readonly text !: string

    @Emit("close")   
    public close(val: boolean){
        return val;
    }

    get dialog(): boolean{
        return this.popupDialog;
    }

    set dialog(newVal: boolean){
        this.close(newVal);
    }
}
