import client from './../apiService';

class CompanyModuleService{
    async IsModernTrade(){
        const url=`CompanyModule/IsModernTrade`;
        return await client.get(url,{
            headers:{
                'Content-Type':'application/json'
            }
        })
    }
}
export default new CompanyModuleService();