import client from "./../apiService";

class GeographyService {
  getAllGeographiesOfType(type: string) {
    const url = `Geography/GetAllGeographiesOfType?type=${type}`;
    return client.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
  GetAllHierarchyLevel() {
    const url = `Geography/GetAllHierarchyLevels`;
    return client.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
  GetOutletsForGeoType(type: string, ids: Array<number>) {
    const url = `Geography/GetOutletsForGeoType?type=` + type;
    return client.post(url, ids, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
  getBeatsLazilyBySearch(
    counter: number,
    searchString: string,
    regionFilter: Array<number>,
    territoryFilter: Array<number>
  ) {
    const url = `Beats/GetBeatBySearch`;
    return client.post(url, {
      counter: counter,
      searchString: searchString,
      regionFilter: regionFilter,
      //territoryFilter: territoryFilter,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
  getBeatsCountByFilter(searchString: string, regionFilter: Array<number>) {
    const url = `Beats/GetBeatCountByFilter`;
    return client.post(url, {
      searchString: searchString,
      regionFilter: regionFilter,
      //territoryFilter: territoryFilter,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
  getBeatsOfPosition(positionId: number) {
    const url = `Beats/GetBeatsOfPosition?positionId=${positionId}`;
    return client.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
  getCompanyZones() {
    const url = `Geography/GetZones`;
    return client.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
  getGeographyByParentId(type: string, parentIds: Array<number>) {
    const url = `Geography/GetAllGeographyByParentId?level=${type}`;
    return client.post(url, parentIds, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
  saveRegionForODS(zoneId: number, RegionIds: Array<number>) {
    debugger;
    const url = `Outlet/CreateUpdateODSForRegion?zoneId=${zoneId}`;
    return client.post(url, RegionIds, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
  getODSRegionsForZone(zoneId: number) {
    const url = `Outlet/GetRegionForOds?zoneId=${zoneId}`;
    return client.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
}

export default new GeographyService();
