import { PositionLevel, StockistType } from "./Enums";
export interface IPosition {
  Name: string;
  Code: string;
  Level: IPositionLevel;
  Employee: IEmployee;
  VacantPosition: boolean;
  ReportingToPosition: IReportingToPositions;
  Id: number;
}
export interface IPositionLevel {
  text: string;
  value: string;
  level: number;
}
export interface IEntityMin {
  id: number;
  name: string;
}
export interface IEntityMinWithParent {
  id: number;
  name: string;
  parentId?: number;
  parentName?: string;
}
export interface IEmployee {
  erpId: string;
  id: number;
  name: string;
}
export interface IReportingToPositions {
  codeId: string;
  id: number;
  level: number;
  name: string;
}
export interface IPositionCodeList {
  id: number;
  companyId: number;
  codeId: string;
  name: string;
  level: number;
  attachedTo: string;
  attachedToId: number;
  attachedErpId: string;
  reportingTo: string;
  reportingToPositionLevel: number;
  reportingToId: number;
  reportingToCodeId: string;
  beatIds?: Array<number>;
  distributorIds?: Array<number>;
  lastAttachedTo: string;
  lastAttachedToId: number;
}

export { PositionLevel };

export enum PositionLevelNomenclature {
  L8Position = 8,
  L7Position = 9,
  L6Position = 10,
  L5Position = 20,
  L4Position = 30,
  L3Position = 40,
  L2Position = 50,
  L1Position = 60,
}

export interface IRegion {
  id: number;
  name: string;
  parentId: number;
}
export interface ITerritory {
  id: number;
  name: string;
  regionId: number;
}
export interface IBeat {
  id: number;
  name: string;
  distributors: Array<string>;
  regionId: number;
  territoryId: number;
}
export interface IDistributor {
  id: number;
  name: string;
  regionName: Array<string>;
  regionId: number;
  erpId: string;
  stockistType: StockistType;
}
export interface IOutlet {
  id: number;
  name: string;
  regionName: Array<string>;
  regionId: number;
}

export interface IPositionCodeView {
  PositionName: string;
  PositionCode: string;
  PositionLevel: string;
  AttachedTo: string;
  ReportingTo: string;
  ReportingToLevel: string;
}
export interface IGeographyList {
  entityId: number;
  entityLevel: string;
  entityName: string;
}
